import React, { Fragment, useEffect, useRef } from "react";
import ScrollTop from "./utils/ScrollTop";
import lottie from "lottie-web";
import NYU from "../assets/img/NYU.png";
import PICT from "../assets/img/PICT.jpeg";

const Education = () => {
  const educationGIF = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: educationGIF.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/img/Education.json"),
    });
  }, []);
  return (
    <Fragment>
      <ScrollTop />
      <div className="container">
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          className="ed-header row"
        >
          <div className="col-12 col-md-6 order-12">
            <div className="educationGIF" ref={educationGIF}></div>
          </div>
          <div className="ed-align col-12 col-md-6 order-1">
            <div className="pr-heading h1 text-center">Education</div>
            <div className="lead mt-4 text-center">
              Basic Qualifications & Certifications
            </div>
          </div>
        </div>
        <div className="pr-body">
          {/* Qualifications */}
          <div>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="h1 text-center mt-5"
            >
              Qualifications
            </div>
            {/* NYU */}
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="card edu-card mt-5"
            >
              <div className="card-body">
                <div className="flex-container-col">
                  <div className="edu-info">
                    <h3 className="college">
                      Master of Science in Computer Science
                    </h3>
                    <h5>New York Universeity, Tandon School of Engineering</h5>
                    <div className="num-font-year">Sept 2023 - May 2025</div>
                    <div className="mt-2 marks">
                      GPA : <span className="num-font-marks">3.926</span>
                    </div>
                  </div>
                  <div className="edu-logo">
                    <a
                      href="https://engineering.nyu.edu/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={NYU} alt="NYU" className="logo" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* PICT */}
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="card edu-card mt-5"
            >
              <div className="card-body">
                <div className="flex-container-col">
                  <div className="edu-info">
                    <h3 className="college">
                      Bachelor of Engineering in Computer Engineering
                    </h3>
                    <h5>Pune Institute of Computer Technology, Pune</h5>
                    <div className="num-font-year">Aug 2019 - June 2023</div>
                    <div className="mt-2 marks">
                      CGPA : <span className="num-font-marks">9.54</span>
                    </div>
                  </div>
                  <div className="edu-logo">
                    <a
                      href="https://pict.edu/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={PICT} alt="PICT" className="logo" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* HSC */}
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="card edu-card mt-5"
            >
              <div className="card-body">
                <div className="edu-info">
                  <h3 className="college">HSC - RADAV College, Bhandup</h3>
                  <div className="num-font-year">2018 - 2019</div>
                  <div className="mt-2 marks">
                    HSC : <span className="num-font-marks">90.3</span> %
                  </div>
                  <div className="mt-2 marks">
                    JEE ADV AIR : <span className="num-font-marks">8424</span>
                  </div>
                </div>
                <div className="qualification">HSC</div>
              </div>
            </div>
            {/* SSC */}
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="card edu-card mt-5 mb-5"
            >
              <div className="card-body">
                <div className="edu-info">
                  <h3 className="college">EuroSchool, Airoli</h3>
                  <div className="num-font-year">2016 - 2017</div>
                  <div className="mt-2 marks">
                    SSC : <span className="num-font-marks">95.6</span> %
                  </div>
                </div>
                <div className="qualification">ICSE</div>
              </div>
            </div>
          </div>
          {/* Certifications */}
          {/* <div className="mb-6">
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="h1 text-center">
              Certifications
            </div>
          </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default Education;
