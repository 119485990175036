import lottie from "lottie-web";
import React, { Fragment, useEffect, useRef } from "react";
import ScrollTop from "./utils/ScrollTop";
import NICE from "../assets/img/NICE.jpg";
import DwebboxLogo from "../assets/img/dwebbox.png";
import PISBLogo from "../assets/img/pisb.jpg";
import DEEMASSET from "../assets/img/DEEMASSET.jpeg";
import NYU from '../assets/img/NYU.jpg'

const Experience = () => {
  const experienceGIF = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: experienceGIF.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/img/Experience.json"),
    });
  }, []);
  return (
    <Fragment>
      <ScrollTop />
      <div className="container">
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          className="row pr-header"
        >
          <div className="col-12 col-md-6 order-12">
            <div className="projectGIF" ref={experienceGIF}></div>
          </div>
          <div className="align-exp col-12 col-md-6 order-1">
            <div className="pr-heading h1 text-center">Experience</div>
            <div className="justify-text-res about-text mt-4">
              I'm currently a Software Engineer Intern at{" "}
              <a
                href="https://deemasset.com/"
                target="_blank"
                className="nyu-violet"
                rel="noreferrer"
              >
                <span className="underline-animation">
                  Deem Asset Management
                </span>
              </a>
              , working on AI-driven financial data pipelines and knowledge
              graph construction using LLMs to enhance investment research. I’m
              also a Graduate Course Assistant at{" "}
              <a
                href="https://engineering.nyu.edu/"
                target="_blank"
                className="nyu-violet"
                rel="noreferrer"
              >
                <span className="underline-animation">NYU</span>
              </a>
              , assisting in Computer Networking coursework. Previously, I
              interned as a Full Stack Developer at{" "}
              <a
                href="https://dwebbox.com/"
                target="_blank"
                className="nyu-violet"
                rel="noreferrer"
              >
                <span className="underline-animation">D-Webbox</span>
              </a>{" "}
              and as a Software Engineer at{" "}
              <a
                href="https://www.nice.com/"
                target="_blank"
                className="nyu-violet"
                rel="noreferrer"
              >
                <span className="underline-animation">NICE Systems Ltd.</span>
              </a>{" "}
              These experiences have strengthened my expertise in software
              development, cloud computing, AI, and system scalability, along
              with strong problem-solving and collaboration skills.
            </div>
          </div>
        </div>
        <div className="pr-body">
          {/* Internships */}
          <div>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="pr-heading h1 text-center mt-5 mb-5"
            >
              Internships
            </div>
            {/* DEEM Asset Management */}
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="row mb-5 d-flex flex-row justify-content-center align-items-center"
            >
              <div className="logoContainer">
                <a
                  href="https://deemasset.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={DEEMASSET}
                    alt="Deem Asset Management"
                    className="logo"
                  />
                </a>
              </div>
              <div className="contentContainer">
                <div className="card edu-card">
                  <div className="card-body">
                    <div className="edu-info">
                      <div className="top-info d-flex flex-row justify-content-between">
                        <h3 className="mb-0 college">
                          Software Engineer Intern
                        </h3>
                        <div className="tenure">Jan 2025 - Present</div>
                      </div>
                      <div className="top-info d-flex flex-row justify-content-between">
                        <div className="comp-name">Deem Asset Management</div>
                        <div className="tenure">North Canton, OH (Remote)</div>
                      </div>
                      <div className="mt-3 marks text-justify">
                        Developing AI-driven financial data pipelines and
                        integrating LLMs for knowledge graphs and RAG
                        architecture to enhance research scalability and
                        retrieval efficiency. Designing cloud-driven scheduling
                        systems using PostgreSQL, AWS, and Neo4j to automate
                        financial report generation.{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* NYU */}
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="row mb-5 d-flex flex-row justify-content-center align-items-center"
            >
              <div className="logoContainer">
                <a
                  href="https://engineering.nyu.edu/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={NYU} alt="NYU Tandon" className="logo" />
                </a>
              </div>
              <div className="contentContainer">
                <div className="card edu-card">
                  <div className="card-body">
                    <div className="edu-info">
                      <div className="top-info d-flex flex-row justify-content-between">
                        <h3 className="mb-0 college">
                          Graduate Course Assistant
                        </h3>
                        <div className="tenure">Sept 2024 - Present</div>
                      </div>
                      <div className="top-info d-flex flex-row justify-content-between">
                        <div className="comp-name">New York University</div>
                        <div className="tenure">New York, NY</div>
                      </div>
                      <div className="mt-3 marks text-justify">
                        Assisting in graduate-level Computer Networking (CS-GY
                        6843), resolving student queries, conducting office
                        hours, and grading assignments. Supporting over 50
                        students while ensuring smooth class operations.{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* NICE */}
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="row mb-5 d-flex flex-row justify-content-center align-items-center"
            >
              <div className="logoContainer">
                <a href="https://nice.com" target="_blank" rel="noreferrer">
                  <img src={NICE} alt="NICE" className="logo" />
                </a>
              </div>
              <div className="contentContainer">
                <div className="card edu-card">
                  <div className="card-body">
                    <div className="edu-info">
                      <div className="top-info d-flex flex-row justify-content-between">
                        <h3 className="mb-0 college">
                          Software Engineer Intern
                        </h3>
                        <div className="tenure">November 2021 - April 2022</div>
                      </div>
                      <div className="top-info d-flex flex-row justify-content-between">
                        <div className="comp-name">NICE System Ltd.</div>
                        <div className="tenure">Pune</div>
                      </div>
                      <div className="mt-3 marks text-justify">
                        Design and Development of "Distributed Job Scheduler".
                        Development of User Interface and Backend - Worker and
                        Master application for "Distributed Job Scheduler" using{" "}
                        <span style={{ fontStyle: "italic" }}>
                          ReactJs, Java, SprintBoot
                        </span>{" "}
                        and implementing a load balancer using using{" "}
                        <span style={{ fontStyle: "italic" }}>Nginx</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Dwebbox */}
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="row mb-5 d-flex flex-row justify-content-center align-items-center"
            >
              <div className="logoContainer">
                <a href="https://dwebbox.com" target="_blank" rel="noreferrer">
                  <img src={DwebboxLogo} alt="Dwebbox" className="logo" />
                </a>
              </div>
              <div className="contentContainer">
                <div className="card edu-card">
                  <div className="card-body">
                    <div className="edu-info">
                      <div className="top-info d-flex flex-row justify-content-between">
                        <h3 className="mb-0 college">
                          Full Stack Developer Intern
                        </h3>
                        <div className="tenure">Aug 2021 - Feb 2022</div>
                      </div>
                      <div className="top-info d-flex flex-row justify-content-between">
                        <div className="comp-name">D-WebBox</div>
                        <div className="tenure">Remote</div>
                      </div>
                      <div className="mt-3 marks text-justify">
                        Development and Debugging of projects -{" "}
                        <a
                          className="project-links"
                          href="https://medvatic.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Medvatic
                        </a>
                        ,{" "}
                        <a
                          className="project-links"
                          href="https://amyra-dwebbox.web.app/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Amyra
                        </a>{" "}
                        and{" "}
                        <a
                          className="project-links"
                          href="https://aagam.org.in/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Aagam
                        </a>
                        , primarily using{" "}
                        <span style={{ fontStyle: "italic" }}>
                          ReactJs, React-Native & Firebase.
                        </span>{" "}
                        Understanding client requirements, suggesting sound
                        technical advice, Software Development Lifecycle (SDLC)
                        management using JIRA.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Volunteer */}
          <div>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="h1 text-center mb-5"
            >
              Volunteer
            </div>
            {/* PISB */}
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="row mb-6 d-flex flex-row justify-content-center align-items-center"
            >
              <div className="logoContainer">
                <a
                  href="https://www.pictieee.in/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={PISBLogo} alt="PISB" className="logo" />
                </a>
              </div>
              <div className="contentContainer">
                <div className="card edu-card">
                  <div className="card-body">
                    <div className="edu-info">
                      <div className="top-info d-flex flex-row justify-content-between">
                        <h3 className="mb-0 college">Webmaster</h3>
                        <div className="tenure">Sep 2021 - Aug 2022</div>
                      </div>
                      <div className="top-info d-flex flex-row justify-content-between">
                        <div className="comp-name">
                          PICT IEEE Student Branch (PISB)
                        </div>
                      </div>
                      <div className="mt-3 marks text-justify">
                        Developing and maintaining the official website of the{" "}
                        <a
                          href="https://pictieee.in/"
                          target="_blank"
                          rel="noreferrer"
                          className="project-links"
                        >
                          PISB
                        </a>
                        . Organization and Management of the annual technical
                        fest "Credenz". Guiding junior club members and
                        volunteers in developing event websites using HTML, CSS
                        and Javascript.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Experience;
